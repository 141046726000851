import { getProcessorsByUser, deleteAnnualHr, postAnnualHr, getAnnualHr, putAnnualHr, getDropdownItems } from '@/api/dataentry-api-generated';
import DeleteDialog from '@/components/DeleteDialog.vue';
import SaveDialog from '@/components/SaveDialog.vue';
import { defineComponent, ref } from '@vue/runtime-core';
export default defineComponent({
    name: 'AnnualHumanResourceComponent',
    components: { DeleteDialog, SaveDialog },
    props: {
        propSelected: Array,
        propCreateNew: Boolean,
        propDuplicateRecord: Boolean,
        propId: String
    },
    data: function () {
        return {
            filter: '',
            effectiveDates: '',
            createNew: this.propCreateNew,
            duplicateRecord: this.propDuplicateRecord,
            id: this.propId,
            loading: false,
            privateRto: ref(),
            qualificationOptions: [
                { value: 'NOT_APPLICABLE', label: 'N/A' },
                { value: 'AMP20117_Certificate_II_in_Meat_Processing_Food_Services', label: 'AMP20117 - Certificate II in Meat Processing (Food Services)' },
                { value: 'AMP20216_Certificate_II_in_Meat_Processing_Smallgoods', label: 'AMP20216 - Certificate II in Meat Processing (Smallgoods)' },
                { value: 'AMP20316_Certificate_II_in_Meat_Processing_Abattoirs', label: 'AMP20316 - Certificate II in Meat Processing (Abattoirs)' },
                { value: 'AMP20415_Certificate_II_in_Meat_Processing_Meat_Retailing', label: 'AMP20415 - Certificate II in Meat Processing (Meat Retailing)' },
                { value: 'AMP30116_Certificate_III_in_Meat_Processing_Boning_Room', label: 'AMP30116 - Certificate III in Meat Processing (Boning Room)' },
                { value: 'AMP30216_Certificate_III_in_Meat_Processing_Food_Services', label: 'AMP30216 - Certificate III in Meat Processing (Food Services)' },
                { value: 'AMP30316_Certificate_III_in_Meat_Processing_Meat_Safety', label: 'AMP30316 - Certificate III in Meat Processing (Meat Safety)' },
                { value: 'AMP30416_Certificate_III_in_Meat_Processing_Rendering', label: 'AMP30416 - Certificate III in Meat Processing (Rendering)' },
                { value: 'AMP30516_Certificate_III_in_Meat_Processing_Slaughtering', label: 'AMP30516 - Certificate III in Meat Processing (Slaughtering)' },
                { value: 'AMP30616_Certificate_III_in_Meat_Processing_General', label: 'AMP30616 - Certificate III in Meat Processing (General)' },
                { value: 'AMP30716_Certificate_III_in_Meat_Processing_Quality_Assurance', label: 'AMP30716 - Certificate III in Meat Processing (Quality Assurance)' },
                { value: 'AMP30815_Certificate_III_in_Meat_Processing_Retail_Butcher', label: 'AMP30815 - Certificate III in Meat Processing (Retail Butcher)' },
                { value: 'AMP30916_Certificate_III_in_Meat_Processing_Smallgoods_General', label: 'AMP30916 - Certificate III in Meat Processing (Smallgoods - General)' },
                { value: 'AMP31016_Certificate_III_in_Meat_Processing_Smallgoods_Manufacture', label: 'AMP31016 - Certificate III in Meat Processing (Smallgoods - Manufacture)' },
                { value: 'AMP31116_Certificate_III_in_Meat_Processing_Livestock_Handling', label: 'AMP31116 - Certificate III in Meat Processing (Livestock Handling)' },
                { value: 'AMP31216_Certificate_III_in_Meat_Processing_Packing_Operations', label: 'AMP31216 - Certificate III in Meat Processing (Packing Operations)' },
                { value: 'AMP40215_Certificate_IV_in_Meat_Processing_General', label: 'AMP40215 - Certificate IV in Meat Processing (General)' },
                { value: 'AMP40315_Certificate_IV_in_Meat_Processing_Leadership', label: 'AMP40315 - Certificate IV in Meat Processing (Leadership)' },
                { value: 'AMP40415_Certificate_IV_in_Meat_Processing_Quality_Assurance', label: 'AMP40415 - Certificate IV in Meat Processing (Quality Assurance)' },
                { value: 'AMP40516_Certificate_IV_in_Meat_Processing_Meat_Safety', label: 'AMP40516 - Certificate IV in Meat Processing (Meat Safety)' },
                { value: 'AMP50215_Diploma_of_Meat_Processing', label: 'AMP50215 - Diploma of Meat Processing' },
                { value: 'AMP50115_Diploma_of_Meat_Processing_Meat_Retailing', label: 'AMP50115 - Diploma of Meat Processing (Meat Retailing)' },
                { value: 'AMP60115_Advanced_Diploma_of_Meat_Processing', label: 'AMP60115 - Advanced Diploma of Meat Processing' },
                { value: 'AMP80115_Graduate_Certificate_in_Agribusiness', label: 'AMP80115 - Graduate Certificate in Agribusiness' },
                { value: 'AMP80215_Graduate_Diploma_of_Agribusiness', label: 'AMP80215 - Graduate Diploma of Agribusiness' }
            ],
            processors: [],
            item: {},
            dropdownOptions: ['Yes', 'No'],
            processorOptions: [{
                    label: '',
                    value: ''
                }],
            processorDisplay: {
                label: '',
                value: ''
            },
            leaveReasonsDropdownItems: [],
            leaveReasonsOptions: [{
                    label: '',
                    value: ''
                }],
            leaveReasonOneDisplay: {
                label: '',
                value: ''
            },
            leaveReasonTwoDisplay: {
                label: '',
                value: ''
            },
            leaveReasonThreeDisplay: {
                label: '',
                value: ''
            },
            leaveReasonFourDisplay: {
                label: '',
                value: ''
            },
            leaveReasonFiveDisplay: {
                label: '',
                value: ''
            }
        };
    },
    computed: {
        uuid() {
            return this.$route.params.uuid;
        },
        selectedItems() {
            return this.propSelected;
        },
        buttonLabel() {
            return 'SAVE';
        },
        years() {
            // Generate values from the past 20 years until the current year
            const currentYear = new Date().getFullYear();
            return Array.from({ length: 21 }, (_, i) => `${currentYear - i} (Jul 1 ${(currentYear - i) - 1} - Jun 30 ${currentYear - i})`);
        }
    },
    created() {
        this.getProcessors();
        this.getLeaveReasonsDropdownItems();
        if (!this.createNew) {
            this.getItem(this.uuid, true);
        }
        if (this.duplicateRecord) {
            if (this.id !== undefined) {
                this.getItem(this.id, false);
            }
        }
    },
    methods: {
        async getItem(uuid, isUpdate) {
            this.loading = true;
            try {
                const result = await getAnnualHr(uuid);
                this.item = result;
                if (isUpdate) {
                    this.item.yearEntry = result.yearEntry;
                    this.processorDisplay.label = result.processorLabel;
                    this.processorDisplay.value = result.processor;
                    this.privateRto = result.isOwnPrivateRTOUsed === null ? undefined : result.isOwnPrivateRTOUsed === true ? 'Yes' : 'No';
                    this.leaveReasonOneDisplay.label = result.leaveReasonOne;
                    this.leaveReasonOneDisplay.value = result.leaveReasonOne;
                    this.leaveReasonTwoDisplay.label = result.leaveReasonTwo;
                    this.leaveReasonTwoDisplay.value = result.leaveReasonTwo;
                    this.leaveReasonThreeDisplay.label = result.leaveReasonThree;
                    this.leaveReasonThreeDisplay.value = result.leaveReasonThree;
                    this.leaveReasonFourDisplay.label = result.leaveReasonFour;
                    this.leaveReasonFourDisplay.value = result.leaveReasonFour;
                    this.leaveReasonFiveDisplay.label = result.leaveReasonFive;
                    this.leaveReasonFiveDisplay.value = result.leaveReasonFive;
                }
                else {
                    this.item.yearEntry = new Date().getFullYear();
                    this.privateRto = result.isOwnPrivateRTOUsed === null ? undefined : result.isOwnPrivateRTOUsed === true ? 'Yes' : 'No';
                }
                this.loading = false;
            }
            catch (error) {
                const errMsg = error;
                if (errMsg.message.includes('ResourceNotFoundException')) {
                    this.closeDialog();
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        saveItem() {
            if (!this.createNew) {
                this.updateItem();
            }
            else {
                this.createItem();
            }
        },
        async updateItem() {
            // Validate the form before proceeding
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.yearEntry = this.item.yearEntry;
                req.isOwnPrivateRTOUsed = this.privateRto === 'Yes' ? true : this.privateRto === 'No' ? false : null;
                try {
                    const req = this.item;
                    req.processor = this.processorDisplay.value;
                    req.leaveReasonOne = this.leaveReasonOneDisplay.value;
                    req.leaveReasonTwo = this.leaveReasonTwoDisplay.value;
                    req.leaveReasonThree = this.leaveReasonThreeDisplay.value;
                    req.leaveReasonFour = this.leaveReasonFourDisplay.value;
                    req.leaveReasonFive = this.leaveReasonFiveDisplay.value;
                    await putAnnualHr(this.uuid, this.item);
                    this.$log.addMessage('Successfully Updated Annual Human Resource');
                    this.$router.push({ name: 'Annual Human Resources' });
                }
                catch (error) {
                    this.$log.addError(error);
                    this.loading = false;
                }
            }
        },
        async createItem() {
            const valid = await this.$refs.form.validate();
            if (valid) {
                this.loading = true;
                const req = this.item;
                req.yearEntry = this.item.yearEntry;
                req.processor = this.processorDisplay.value;
                req.isOwnPrivateRTOUsed = this.privateRto === 'Yes' ? true : this.privateRto === 'No' ? false : null;
                req.leaveReasonOne = this.leaveReasonOneDisplay.value;
                req.leaveReasonTwo = this.leaveReasonTwoDisplay.value;
                req.leaveReasonThree = this.leaveReasonThreeDisplay.value;
                req.leaveReasonFour = this.leaveReasonFourDisplay.value;
                req.leaveReasonFive = this.leaveReasonFiveDisplay.value;
                try {
                    await postAnnualHr(req);
                    this.$log.addMessage('Successfully created new Human Resource');
                    this.$emit('refresh');
                    this.closeDialog();
                }
                catch (error) {
                    const errorObj = error;
                    if (errorObj.message.includes('ResourceAlreadyExistsException')) {
                        const uuid = errorObj.message.substring(31, errorObj.message.length);
                        const msg = `A record with the same Human Resource already exists: <a href="#/AnnualHumanResource/${uuid}">VIEW</a>`;
                        const errorMsg = {
                            type: 'warning',
                            textColor: 'black',
                            timeout: 5000,
                            position: 'top',
                            message: msg,
                            html: true
                        };
                        this.$log.addCustomMessage(errorMsg);
                    }
                    else {
                        this.$log.addError(error);
                    }
                    this.loading = false;
                }
            }
        },
        async deleteItem() {
            try {
                const result = await deleteAnnualHr(this.uuid);
                this.item = result;
                this.$log.addMessage('Successfully deleted Human Resource');
                this.closeDialog();
            }
            catch (error) {
                this.$log.addError(error);
            }
        },
        closeDialog() {
            if (this.createNew) {
                this.$emit('show-hide-dialog');
            }
            else {
                this.goBack();
            }
        },
        goBack() {
            this.$router.push({ name: 'Annual Human Resources' });
        },
        async getProcessors() {
            this.loading = true;
            try {
                const result = await getProcessorsByUser({ pageSize: 1000000, deleted: false });
                this.processors = result.items;
                this.processorOptions = this.getProcessorOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getProcessorOptions() {
            return this.processors.map(i => {
                return {
                    label: i.processorLabel.slice(0, -6),
                    value: i.uuid
                };
            });
        },
        filterProcessorOptions(val, update) {
            if (val === '') {
                update(() => {
                    // No filter - display default list
                    this.processorOptions = this.getProcessorOptions();
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.processorOptions = this.getProcessorOptions().filter(v => v.label.toLowerCase().indexOf(needle) > -1);
            });
        },
        async getLeaveReasonsDropdownItems() {
            this.loading = true;
            try {
                const result = await getDropdownItems({
                    screen: 'ANNUAL_HUMAN_RESOURCE',
                    field: 'leaveReasons',
                    pageSize: 1000000,
                    deleted: false
                });
                this.leaveReasonsDropdownItems = result.items;
                this.leaveReasonsOptions = this.getLeaveReasonsDropdownItemsOptions();
                this.loading = false;
            }
            catch (error) {
                this.$log.addError(error);
                this.loading = false;
            }
        },
        getLeaveReasonsDropdownItemsOptions() {
            return this.leaveReasonsDropdownItems.map(i => {
                return {
                    label: i.dropdownItemValue,
                    value: i.dropdownItemValue
                };
            });
        }
    }
});
